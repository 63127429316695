import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import { router } from "@/router";
import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import VueSelect from "vue3-select2-component";
import VueApexCharts from "vue3-apexcharts";
import DatePicker from "vue3-datepicker";
import FlagIcon from "vue-flag-icon";
import VueFeather from "vue-feather";
import StarRating from "vue-star-rating";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import ThemifyIcon from "vue-themify-icons";
import SimpleLineIcons from "vue-simple-line";
import VueEasyLightbox from "vue-easy-lightbox";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import VueTheMask from "vue-the-mask";

import piniaPersistedState from "pinia-plugin-persistedstate";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "boxicons/css/boxicons.min.css";
import "pe7-icon/dist/dist/pe-icon-7-stroke.css";
import "typicons.font/src/font/typicons.css";
import "weathericons/css/weather-icons.css";
import "ionicons-npm/css/ionicons.css";
import "@/assets/css/feather.css";
import "@/assets/css/tabler-icons.css";
import "@/assets/scss/main.scss";

// *************** Components ***************
import Breadcrumb from "@/components/breadcrumb/page-header.vue";
import DashboardHeader from "@/components/breadcrumb/dashboard-header.vue";

// *************** Layout Component ***************
import Header from "@/views/layouts/layouts-header.vue";
import Sidebar from "@/views/layouts/layouts-sidebar.vue";
import VerticalSidebar from "@/views/layouts/vertical-sidebar.vue";
import FilesSidebar from "@/views/layouts/files-sidebar.vue";
import SideSettings from "@/views/layouts/side-settings.vue";
import SettingsTabs from "@/views/pages/settings/settings-tabs.vue";

import IndexLoader from "@/views/layouts/index-loader.vue";

import MainSidebar from "@/components/main-sidebar.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import MainFooter from "@/components/main-footer.vue";

// *************** Page Component ***************

import DealsTable from "@/views/pages/crm/deals/deals-table.vue";
import DealsTableAll from "@/views/pages/crm/deals/deals-table-all.vue";
import DealsTableNew from "@/views/pages/crm/deals/deals-table-new.vue";
import DealsTableAccepted from "@/views/pages/crm/deals/deals-table-accepted.vue";
import GeneralSidebar from "@/views/pages/settings/general-settings/general-sidebar.vue";
import WonChart from "@/views/pages/crm/analytics/won-chart.vue";
import ActivitiesTable from "@/views/pages/crm/activities/activities-table.vue";
import ChatContent from "@/views/pages/application/chat/chat-content.vue";
import DashboardStat from "@/components/dashboard-stat.vue";
import DealsDashboardTable from "@/views/pages/dashboard/deals-dashboard/deals-dashboard-table.vue";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersistedState);

// *************** Components ***************
app.component("page-header", Breadcrumb);
app.component("dashboard-header", DashboardHeader);

// *************** Layout Component ***************
app.component("layouts-header", Header);
app.component("layouts-sidebar", Sidebar);
app.component("vertical-sidebar", VerticalSidebar);
app.component("files-sidebar", FilesSidebar);
app.component("side-settings", SideSettings);
app.component("settings-tabs", SettingsTabs);
app.component("index-loader", IndexLoader);
app.component("main-sidebar", MainSidebar);
app.component("admin-sidebar", AdminSidebar);
app.component("main-footer", MainFooter);

// *************** Page Component ***************
app.component("activities-table", ActivitiesTable);
app.component("deals-table", DealsTable);
app.component("deals-table-all", DealsTableAll);
app.component("deals-table-new", DealsTableNew);
app.component("deals-table-accepted", DealsTableAccepted);
app.component("deals-dashboard-table", DealsDashboardTable);
app.component("won-chart", WonChart);
app.component("chat-content", ChatContent);
app.component("general-sidebar", GeneralSidebar);
app.component("dashboard-stat", DashboardStat);

// *************** modal Component ***************

app.component("date-picker", DatePicker);
app.component("star-rating", StarRating);
app.component("vue-select", VueSelect);
app.component(VueFeather.name, VueFeather);

app
  .use(pinia)
  .use(VueApexCharts)
  .use(VueEasyLightbox)
  .use(Antd)
  .use(BootstrapVue3)
  .use(BToastPlugin)
  .use(ThemifyIcon)
  .use(SimpleLineIcons)
  .use(VueToast, {
    position: "top-right",
  });
app.use(FlagIcon);
app.use(ToastPlugin);
app.use(CKEditor);
app.use(VueTheMask);
app.use(router);
app.mount("#app");
