<template>
  <div class="account-content login-page">
    <div class="login-wrapper">
      <div class="login-form">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="flex-fill"
        >
          <div class="mx-auto">
            <div class="text-center mb-4 login-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </div>
            <div class="mb-4">
              <p class="login-title">Войдите в свою учетную запись</p>
            </div>
            <div class="mb-3">
              <label class="col-form-label">Логин</label>
              <div class="position-relative">
                <Field
                  name="email"
                  type="text"
                  v-model="form.email"
                  class="form-control"
                  placeholder="Введите логин"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div class="emailshow text-danger" id="email">
                  {{ emailError }}
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="col-form-label">Пароль</label>
              <div class="pass-group">
                <Field
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="form.password"
                  placeholder="Введите пароль"
                  class="form-control pass-input mt-2"
                  :class="{ 'is-invalid': errors.password }"
                />
                <span @click="toggleShow" class="toggle-password">
                  <i
                    :class="{
                      'ti ti-eye': showPassword,
                      'ti ti-eye-off': !showPassword,
                    }"
                  ></i>
                </span>
                <div class="invalid-feedback">{{ errors.password }}</div>
                <div class="emailshow text-danger" id="password">
                  {{ passwordError }}
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="text-end">
                <router-link
                  to="/forgot-password"
                  class="text-primary fw-medium link-hover"
                  >Забыли пароль?</router-link
                >
              </div>
            </div>
            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-primary w-100 login-btn"
                :disabled="isSubmitting"
              >
                Войти
              </button>
            </div>
          </div>
        </Form>
        <div
          class="login-contacts text-center d-flex justify-content-center gap-3"
        >
          <p class="fw-medium">
            E: <a href="mailto:presales@gcap.ru">presales@gcap.ru</a>
          </p>
          <p>Т: <a href="tel:+78047006400">+7 (804) 700-64-00</a></p>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <main-footer />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { startEventSource } from "@/services/sseService";
export default {
  components: {
    Form,
    Field,
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const showPassword = ref(false);
    const emailError = ref("");
    const passwordError = ref("");
    const isSubmitting = ref(false);

    const form = ref({
      email: "",
      password: "",
    });

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    const schema = Yup.object().shape({
      email: Yup.string().required("Email обязателен"),
      password: Yup.string()
        .min(6, "Пароль должен быть длиннее 6 символов")
        .required("Пароль обязателен"),
    });

    const onSubmit = async (values) => {
      emailError.value = "";
      passwordError.value = "";
      isSubmitting.value = true;

      try {
        await authStore.login(values.email, values.password);
        router.push("/deals");
        startEventSource();
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400) {
            emailError.value = data.message || "Некорректные данные";
          } else if (status === 401) {
            passwordError.value = "Неверный пароль";
          } else {
            console.error("Unexpected error:", data);
          }
        } else {
          console.error("Error connecting to the server:", error);
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      showPassword,
      toggleShow,
      schema,
      onSubmit,
      emailError,
      passwordError,
      form,
      isSubmitting,
    };
  },
};
</script>
