import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";

import Login from "@/views/pages/pages/authentication/auth-login.vue";
import Register from "@/views/pages/pages/authentication/auth-register.vue";
import Forgot_Password from "@/views/pages/pages/authentication/forgot-password.vue";
import Reset_Password from "@/views/pages/pages/authentication/reset-password.vue";
import Email_Verification from "@/views/pages/pages/authentication/email-verification.vue";
import Crm_Error from "@/views/pages/pages/error/crm-error.vue";
import Error_404 from "@/views/pages/pages/error/error-404.vue";
import Error_500 from "@/views/pages/pages/error/error-500.vue";
import Crm_Settings from "@/views/pages/settings/crm-settings.vue";
import Crm_Profile from "@/views/pages/settings/general-settings/crm-profile.vue";
import Crm_Security from "@/views/pages/settings/general-settings/crm-security.vue";
import Crm_Notifications from "@/views/pages/settings/general-settings/crm-notifications.vue";

import Activities from "@/views/pages/crm/activities/activities-list.vue";

import RequestsIndex from "@/views/pages/requests/requests-index.vue";
import DealsIndex from "@/views/pages/deals/deals-index.vue";
import DealDetail from "@/views/pages/deals/deal-detail/deal-detail.vue";
import ChatIndex from "@/views/pages/chat/chat-index.vue";

import AdminIndex from "@/views/pages/pages/admin/admin-index.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "Авторизация | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "Регистрация | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: Forgot_Password,
    meta: {
      title: "Восстановление пароля | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: Reset_Password,
    meta: {
      title: "Восстановление пароля | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/email-verification",
    name: "email-verification",
    component: Email_Verification,
    meta: {
      title: "Верификация email | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/error",
    component: Crm_Error,
    children: [
      { path: "", redirect: "/error/error-404" },
      {
        path: "error-404",
        component: Error_404,
        meta: {
          title: "Ошибка 404 | Личный кабинет Партнера Автопартнер",
        },
      },
      {
        path: "error-500",
        component: Error_500,
        meta: {
          title: "Ошибка 500 | Личный кабинет Партнера Автопартнер",
        },
      },
    ],
  },

  {
    path: "/requests",
    component: RequestsIndex,
    meta: {
      title: "Мои заявки | Личный кабинет Партнера Автопартнер",
      requiresAuth: true,
    },
  },
  {
    path: "/deals",
    component: DealsIndex,
    meta: {
      requiresAuth: true,
      title: "Сделки | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/deals/:id",
    component: DealDetail,
    meta: {
      requiresAuth: true,
      title: "Просмотр сделки | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/activities",
    component: Activities,
    meta: {
      requiresAuth: true,
      title: "Уведомления | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/chat",
    component: ChatIndex,
    meta: {
      requiresAuth: true,
      title: "Чат | Личный кабинет Партнера Автопартнер",
    },
  },
  {
    path: "/admin",
    children: [
      { path: "", redirect: "/admin/analytics" },
      {
        path: "analytics",
        component: AdminIndex,
        meta: {
          title: "Аналитика | Личный кабинет Партнера Автопартнер",
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/settings",
    component: Crm_Settings,
    children: [
      { path: "", redirect: "/settings/profile", meta: { requiresAuth: true } },
      {
        path: "profile",
        component: Crm_Profile,
        meta: {
          title: "Профиль | Личный кабинет Партнера Автопартнер",
          requiresAuth: true,
        },
      },
      {
        path: "security",
        component: Crm_Security,
        meta: {
          title: "Безопасность | Личный кабинет Партнера Автопартнер",
          requiresAuth: true,
        },
      },
      {
        path: "notifications",
        component: Crm_Notifications,
        meta: {
          title: "Уведомления | Личный кабинет Партнера Автопартнер",
          requiresAuth: true,
        },
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !auth.isAuthenticated) {
    if (to.name !== "login") {
      next({ name: "login" });
    } else {
      next(false);
    }
  } else {
    console.log("next");
    next();
  }
});
