import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";
import { useAuthStore } from "./auth";
export const useDealStore = defineStore("deal", {
  state: () => ({
    deal: {},
    activities: [],
  }),
  getters: {
    getDeal: (state) => state.deal,
    getActivities: (state) => state.activities.flatMap((item) => item),
  },
  actions: {
    async fetchDeal(leadId) {
      if (!leadId) return;
      try {
        const response = await apiClient.get(`${baseUrl}/deals/${leadId}`);
        this.deal = response.data;
      } catch (error) {
        console.error("Error fetching deal:", error);
      }
    },
    async fetchActivities(leadId) {
      const authStore = useAuthStore();
      try {
        const response = await apiClient.get(
          `${baseUrl}/activity-histories/${leadId}`
        );
        this.activities = response.data
          .filter((item) => item.manager_id === authStore.getCurrentManagerID)
          .map((item) => item.activities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    },
    async setCommission(leadId, data) {
      try {
        const response = await apiClient.post(
          `${baseUrl}/deals/${leadId}/commissions`,
          {
            commission: +data.commission,
            mangerId: this.deal.fr_manager_lk.uuid,
          }
        );
        return response;
      } catch (error) {
        console.error("Error setting commission:", error);
      }
    },
    async getCommission(leadId, mangerId) {
      try {
        const response = await apiClient.get(
          `${baseUrl}/deals/${leadId}/${mangerId}/commissions`
        );
        return response;
      } catch (error) {
        console.error("Error getting commission:", error);
      }
    },
    async getAnswers() {
      try {
        const response = await apiClient.post(
          `${baseUrl}/deals/answers/commissions`
        );
        return response;
      } catch (error) {
        console.error("Error getting answers:", error);
      }
    },
  },
});
